<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Save')}}</button>
    </div>
    <div class="service-infomration">
      <el-form class="service-form" ref="service-form" :model="form" label-width="150px" label-position="left">
        <div class="create-service-form">
          <div class="row">
            <el-form-item :label="$t('Service Name (Chinese)')" style="max-width: 100%">
              <el-input v-model="form.serviceNameZh"></el-input>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item :label="$t('Service Name (English)')" style="max-width: 100%">
              <el-input v-model="form.serviceNameEn"></el-input>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item :label="$t('Service Price') + ' (HK$)'" style="max-width: 100%">
              <el-input-number v-model="form.servicePrice" :precision="1" :step="0.1"></el-input-number>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';

export default {
  name: 'ServiceForm',
  data(){
    //Default form value
    let form = {
      serviceNameZh: '',
      serviceNameEn: '',
      servicePrice: '',
    };

    return {
      form,
    };
  },
  methods:{
    save(){
      this.$emit('action', this.form);
    },
  },
  watch: {
    'currentService': function(){
      this.form = {
        serviceNameZh: this.currentService.service_name_zh,
        serviceNameEn: this.currentService.service_name_en,
        servicePrice: this.currentService.service_price,
      };
    },
  },
  computed: mapState({
    currentService: state => state.service.currentService,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.service{
  .action-button-wrapper{
    text-align: right;
    margin-bottom: 50px;
  }
  .form-wrapper{
    padding-left: 20px;
  }
  .service-form{
    padding-left: 20px;
  }
  .el-card__header{
    background-color: black;
    color: white;
  }
  .el-form-item.email{
    margin-right: 30px;
  }
  .service-orders-card{
    margin-top: 50px;
  }
}
</style>
